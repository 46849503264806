import React from "react";
import Socials from "../UI/Socials/Socials";

const Footer = () => {
  return (
    <div className="footer">
      <div className="container">
        <div className="footer__wrapper">
          <Socials />
          <div className="copyright">All rights reserved NIS CEP 2024</div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
