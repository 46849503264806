import progressImg from "./../../assets/image/progress/progressSVG.svg";

import { motion } from "framer-motion";

const newsAnimation = {
  hidden: {
    opacity: 0,
  },
  visible: (custom) => ({
    opacity: 1,
    transition: { delay: custom * 0.9 },
  }),
};

const Progress = () => {
  return (
    <motion.section
      initial="hidden"
      whileInView="visible"
      viewport={{ amount: 0.9, once: true }}
      className="progress"
    >
      <motion.div className="progress__block" variants={newsAnimation}>
        <div className="progress__texts">
          <div className="progress__title">Следи за своим прогрессом</div>
          <div className="progress__subtitle">
            После прохождения лаборатории у тебя будет возможность оценить
            полученые знания, путем прохождения контрольных задач.
          </div>
        </div>
        <div className="progress__image-wrapper">
          <img src={progressImg} alt="progress" className="progress__image" />
        </div>
      </motion.div>
    </motion.section>
  );
};

export default Progress;
