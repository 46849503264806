import { motion } from "framer-motion";

const previewTextAnimation = {
  hidden: {
    opacity: 0,
  },
  visible: (custom) => ({
    opacity: 1,
    transition: { delay: custom * 0.2 },
  }),
};

const PreviewMedia = () => {
  return (
    <motion.section
      initial="hidden"
      whileInView="visible"
      viewport={{ amount: 0.2, once: true }}
      className="media__wrapper"
    >
      <div className="preview__video__wrapper">
        <iframe
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/hFUZuusJllY?si=r43RmKHKraaZofrT&autoplay=1"
          title="NIS LAB"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          className="preview__video"
          autoplay
          allowfullscreen
        ></iframe>
      </div>
      <div className="content">
        <motion.div custom={1} variants={previewTextAnimation}>
          <p className="preview__title">
            Погрузись в мир виртуальных экспериментов!
          </p>
        </motion.div>

        <motion.div custom={2} variants={previewTextAnimation}>
          <p className="preview__subtitle">
            NIS Lab представляет цифровые лаборатории по физике, химии и
            биологии
          </p>
        </motion.div>
      </div>
    </motion.section>
  );
};

export default PreviewMedia;
