import React from 'react';
import { Route, Routes } from "react-router-dom";

import Preview from '../Preview/Preview';

const AppRoutes = () => {
  return (
    <Routes>
      <Route path='/' index element={<Preview />}></Route>
    </Routes>
  )
}

export default AppRoutes;
