import { motion } from "framer-motion";

const robotAnimation = {
  hidden: {
    x: 0,
    opacity: 0,
    scale: 0.9,
  },
  visible: (custom) => ({
    x: 0,
    opacity: 1,
    scale: 1,
    transition: { delay: custom * 0.9 },
  }),
};

const Robot = () => {
  return (
    <motion.section
      initial="hidden"
      whileInView="visible"
      viewport={{ amount: 0.2, once: true }}
      className="robot"
    >
      <div className="robot__media">
        <motion.div variants={robotAnimation} className="creature"></motion.div>
        <motion.div
          custom={1}
          variants={robotAnimation}
          className="eclipse"
        ></motion.div>
        <motion.div className="media__texts" variants={robotAnimation}>
          <div className="media__title">NISLAB</div>
          <div className="media__subtitle">
            Расширяй новые горизонты цифрового обучения!
          </div>
        </motion.div>
      </div>
    </motion.section>
  );
};

export default Robot;
