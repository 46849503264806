import React from "react";
import { MENU } from "../../../utils/constants";
import { Link } from "react-router-dom";

import ThemeMode from "../ThemeMode/ThemeMode";

const Nav = () => {
  return (
    <nav className="nav">
      <ul className="nav__list">
        {MENU.map(({ name, link }) => (
          <li className="nav__item" key={link}>
            <Link to={`/${link}`}>{name}</Link>
          </li>
        ))}
      </ul>
      <ThemeMode />
    </nav>
  );
};

export default Nav;
