import Biology from "./Biology.jsx";
import Chemistry from "./Chemistry.jsx";
import Physics from "./Physics.jsx";

const Subjects = () => {
  return (
    <section className="subjects">
      <Chemistry />
      <Physics />
      <Biology />
    </section>
  );
};

export default Subjects;
