import React from "react";
import Robot from "./Robot";

import Slider from "../UI/Swiper/Slider";
import Subjects from "../Subjects/Subjects";
import Teams from "../Teams/Teams";
import Progress from "../Progress/Progress";
import Mobile from "../Mobile/Mobile";
import News from "../News/News";
import PreviewMedia from "./PreviewMedia";

const Preview = () => {
  return (
    <section>
      <div className="container">
        <div className="preview">
          <Robot />
          <PreviewMedia />
        </div>
        <Subjects />
        <Slider />
        <Progress />
        <Mobile />
        <News />
        {/* <Teams /> */}
      </div>
    </section>
  );
};

export default Preview;
