import physics from "./../../assets/image/bg/physics.png";
import { motion } from "framer-motion";

import {
  PHYSUBJECTS7,
  PHYSUBJECTS8,
  PHYSUBJECTS9,
  PHYSUBJECTS10,
} from "./../../utils/constants.js";
import { Link } from "react-router-dom";

const subjPhyAnimation = {
  hidden: {
    opacity: 0,
  },
  visible: (custom) => ({
    opacity: 1,
    transition: { delay: custom * 0.9 },
  }),
};

const Physics = () => {
  return (
    <motion.section
      className="subjects__wrapper"
      initial="hidden"
      whileInView="visible"
      viewport={{ amount: 0.3, once: true }}
    >
      <motion.div
        className="subjects__wrapper"
        custom={1}
        variants={subjPhyAnimation}
      >
        <img src={physics} alt="physics" />
        <div className="subjects__block">
          <div className="subjects__title">Лабораторные работы по физике:</div>
          <div className="subjects__classNumber">7 класс</div>
          <div className="subjects__lists">
            {PHYSUBJECTS7.map(({ id, name, link }) => (
              <Link to={link} target="_blank" className="subjects__list" key={id}>
                  {name}
              </Link>
            ))}
          </div>
        </div>
        <div className="subjects__block">
          <div className="subjects__classNumber margTop30px">8 класс</div>
          <div className="subjects__lists">
            {PHYSUBJECTS8.map(({ id, name, link }) => (
              <Link to={link} target="_blank" className="subjects__list maxWidt100per" key={id}>
                  {name}
              </Link>
            ))}
          </div>
        </div>
        <div className="subjects__block">
          <div className="subjects__classNumber margTop30px">
            9 класс
          </div>
          <div className="subjects__lists dislpayColumn">
          {PHYSUBJECTS9.map(({ id, name, link }) => (
              <Link to={link} target="_blank" className="subjects__list maxWidt100per" key={id}>
                  {name}
              </Link>
            ))}
          </div>
        </div>
        <div className="subjects__block">
          <div className="subjects__classNumber margTop30px">
            10 класс (в разработке)
          </div>
          <div className="subjects__lists dislpayColumn">
            {PHYSUBJECTS9.map(({ id, name }) => (
              <div
                className="subjects__list maxWidth100per colorBlack"
                key={id}
              >
                {name}
              </div>
            ))}
          </div>
        </div>
      </motion.div>
    </motion.section>
  );
};

export default Physics;
