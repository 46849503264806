import { Link } from "react-router-dom";
import chemistry from "./../../assets/image/bg/chimestryBG.png";
import {
  CHEMSUBJECTS7,
  CHEMSUBJECTS8,
  CHEMSUBJECTS9,
  CHEMSUBJECTS10,
} from "./../../utils/constants.js";
import { motion } from "framer-motion";

const subjChemAnimation = {
  hidden: {
    opacity: 0,
  },
  visible: (custom) => ({
    opacity: 1,
    transition: { delay: custom * 0.9 },
  }),
};

const Chemistry = () => {
  return (
    <motion.section
      initial="hidden"
      whileInView="visible"
      viewport={{ amount: 0.3, once: true }}
    >
      <motion.div className="subjects__wrapper" variants={subjChemAnimation}>
        <img src={chemistry} alt="chemistry" />
        <div className="subjects__block">
          <div className="subjects__title">Лабораторные работы по химии:</div>
          <div className="subjects__classNumber">7 класс</div>
          <div className="subjects__lists">
            {CHEMSUBJECTS7.map(({ id, name, link }) => (
              <Link to={link} target="_blank" className="subjects__list" key={id}>
                  {name}
              </Link>
            ))}
          </div>
        </div>
        <div className="subjects__block">
          <div className="subjects__classNumber margTop30px">8 класс</div>
          <div className="subjects__lists dislpayColumn">
            {CHEMSUBJECTS8.map(({ id, name, link }) => (
              <Link to={link} target="_blank" className="subjects__list maxWidth100per" key={id}>
                  {name}
              </Link>
            ))}
          </div>
        </div>
        <div className="subjects__block">
          <div className="subjects__classNumber margTop30px">9 класс </div>
          <div className="subjects__lists dislpayColumn">
          {CHEMSUBJECTS9.map(({ id, name, link }) => (
              <Link to={link} target="_blank" className="subjects__list maxWidth100per" key={id}>
                  {name}
              </Link>
            ))}
          </div>
        </div>
        <div className="subjects__block">
          <div className="subjects__classNumber margTop30px">10 класс (в разработке)</div>
          <div className="subjects__lists dislpayColumn">
            {CHEMSUBJECTS10.map(({ id, name }) => (
              <div
                className="subjects__list maxWidth100per colorBlack"
                key={id}
              >
                {name}
              </div>
            ))}
          </div>
        </div>
      </motion.div>
    </motion.section>
  );
};

export default Chemistry;
