import React from "react";

const ThemeMode = () => {
  return (
    <button>
      <svg style={{width: "16px", height: "16px"}}>
        <use
          xlinkHref={`${process.env.PUBLIC_URL}/images/sprite.svg#moon`}
        ></use>
      </svg>

      <svg style={{width: "16px", height: "16px"}}>
        <use
          xlinkHref={`${process.env.PUBLIC_URL}/images/sprite.svg#sun`}
        ></use>
      </svg>
      {/* <img
        src={`${process.env.PUBLIC_URL}/images/sprite.svg#wt-icon-{{sun}}`}
        alt="light"
        className="theme__mode"
      /> */}
    </button>
  );
};

export default ThemeMode;
