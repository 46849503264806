import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import v1 from "../../../assets/image/swiper/1.webp";
import v2 from "../../../assets/image/swiper/2.webp";
import v3 from "../../../assets/image/swiper/3.webp";
import v4 from "../../../assets/image/swiper/4.webp";
import v5 from "../../../assets/image/swiper/5.webp";
import v6 from "../../../assets/image/swiper/6.webp";
import v7 from "../../../assets/image/swiper/7.webp";

import { Autoplay, Pagination, Navigation } from "swiper/modules";

const Slider = () => {
  return (
    <div className="slider">
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 10000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide>
          <img src={v1} className="slder__img" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={v2} className="slder__img" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={v3} className="slder__img" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={v4} className="slder__img" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={v5} className="slder__img" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={v6} className="slder__img" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={v7} className="slder__img" />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default Slider;
