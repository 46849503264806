import { Link } from "react-router-dom";
import biology from "./../../assets/image/bg/biology.png";
import {
  BIOSUBJECTS7,
  BIOSUBJECTS8,
  BIOSUBJECTS9,
  BIOSUBJECTS10,
} from "./../../utils/constants.js";
import { motion } from "framer-motion";

const subjBioAnimation = {
  hidden: {
    opacity: 0,
  },
  visible: (custom) => ({
    opacity: 1,
    transition: { delay: custom * 0.9 },
  }),
};

const Biology = () => {
  return (
    <motion.section
      className="subjects__wrapper"
      initial="hidden"
      whileInView="visible"
      viewport={{ amount: 0.3, once: true }}
    >
      <motion.div
        className="subjects__wrapper"
        custom={1}
        variants={subjBioAnimation}
      >
        <img src={biology} alt="chemistry" />
        <div className="subjects__block">
          <div custom={1} className="subjects__title">
            Лабораторные работы по биологии:
          </div>
          <div className="subjects__classNumber">7 класс</div>
          <div className="subjects__lists">
            {BIOSUBJECTS7.map(({ id, name, link }) => (
              <Link to={link} target="_blank" className="subjects__list" key={id}>
                  {name}
              </Link>
            ))}
          </div>
        </div>
        <div className="subjects__block">
          <div className="subjects__classNumber margTop30px">8 класс</div>
          <div className="subjects__lists">
            {BIOSUBJECTS8.map(({ id, name, link }) => (
              <Link to={link} target="_blank" className="subjects__list maxWidth100per" key={id}>
                  {name}
              </Link>
            ))}
          </div>
        </div>
        <div className="subjects__block">
          <div className="subjects__classNumber margTop30px">
            9 класс
          </div>
          <div className="subjects__lists dislpayColumn">
          {BIOSUBJECTS9.map(({ id, name, link }) => (
              <Link to={link} target="_blank" className="subjects__list maxWidth100per" key={id}>
                  {name}
              </Link>
            ))}
          </div>
        </div>
        <div className="subjects__block">
          <div className="subjects__classNumber margTop30px">
            10 класс (в разработке)
          </div>
          <div className="subjects__lists dislpayColumn">
            {BIOSUBJECTS10.map(({ id, name }) => (
              <div
                className="subjects__list maxWidth100per colorBlack"
                key={id}
              >
                {name}
              </div>
            ))}
          </div>
        </div>
      </motion.div>
    </motion.section>
  );
};

export default Biology;
